@font-face {
  font-family: 'Heading-Regular';
  src:
  url(./fonts/Heading-Regular.ttf);
}

@font-face {
  font-family: 'Heading-Bold';
  src:
  url(./fonts/Heading-Bold.ttf);
}

@font-face {
  font-family: 'Heading-Book';
  src:
  url(./fonts/Heading-Book.ttf);
}

@font-face {
  font-family: 'Heading-ExtraBold';
  src:
  url(./fonts/Heading-ExtraBold.ttf);
}

@font-face {
  font-family: 'Heading-ExtraLight';
  src:
  url(./fonts/Heading-ExtraLight.ttf);
}

@font-face {
  font-family: 'Heading-Heavy';
  src:
  url(./fonts/Heading-Heavy.ttf);
}


@font-face {
  font-family: 'Heading-Light';
  src:
  url(./fonts/Heading-Light.ttf);
}


@font-face {
  font-family: 'Heading-Thin';
  src:
  url(./fonts/Heading-Thin.ttf);
}







.logoContainer{
  width:100%;
  height: 100vh;
  background-color:RGB(0, 0, 0, 0.5);
  position: relative;
  z-index: 50;
  background-color: black;

}

.logoPuerta{
  margin-left: auto;
  margin-right: auto;
  padding-top: 10vh;
  display: block;
  width: 20%;
}



.scrollGif{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  display: block;
  bottom:0%;
  left: 50%;
}

.videoContainer{
  width: 100%;
  height: 100vh;
  object-fit: cover ;
  position: absolute;
  z-index: -5;
  opacity: 0.5;
}

.videoContainer2{
  width: 100%;
  height: 100vh;
  object-fit: cover ;
  position: absolute;

  opacity: 0.5;
}

.imgCarousel{
  width: 100%;
  height: 100vh;
}

.misionVision{
  width: 100%;
  height: 100vh;
  background-color: #adadad;
  display:flex;

}


.misionVision h1{
  font-family: "Birds";
  margin:0;
  color:#926123;
  font-size: 60px;
}

.misionVision p{
  font-family: "Quesha";
  font-size: 25px;
}

.misionContainer{
  width: 40%;
  position: absolute;
  margin-top:15%;
}

.visionContainer{
  width: 40%;
  position: absolute;
  margin-top:15%;
  right:0;
}

.servicios{
  width: 100%;
  height: 100vh;
  background-color: #926123;
}

.servicios h1{
  font-family: "Birds";
  text-align: center;
  color: White;
  margin: 0%;
  font-size: 60px;
}

.serviciosGridContainer{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

/* relevant styles */
.img__wrap {
  position: relative;
  height: 40vh;
  width: 100%;
  background-color: red;
}


.img__img{
  width: 100%;
  height: 100%;
}

.img__description_layer {
  position: absolute;
  height:70%;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(36, 62, 206, 0.6);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.img__wrap:hover .img__description_layer {
  visibility: visible;
  opacity: 1;
}

.img__description {
  transition: .2s;
  transform: translateY(1em);
}

.img__wrap:hover .img__description {
  transform: translateY(0);
}



/*MENU*/

/* Position and sizing of burger button */
.menuLogin {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 20px;

}

.menuTiendas {
  position: fixed;
  width: 85px;
  height: 85px;
  right: 30px ;
  top: 10px;
  color: white;
  font-size: 8vh;
  text-align: justify;
}



.menu-tiendas-item{
  width:100%;
  height: auto;
  margin-bottom: 5%;
  padding:1%;
  border: solid 2px white;
  border-radius: 15px;
  display:flex!important;
}

.shop-icon{
  width: 25%;
}

.tiendas-item-description{
  width: 75%;
  height: auto;

}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: Tan;

}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #17202A;
}

.logoMenu{
  width: 50%;
  border: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;

}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;

}


/* General sidebar styles */
.bm-menu {
  background: #926123;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  overflow:hidden ;

}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item{
  color:white;
  font-family: "Heading-Book";
  font-size: 15px;
  border:none;
  text-decoration: none;
  outline: none;
  margin:5%;
}

.menu-item:hover{
  color:#b8b7ad;
}

.logout-link{
  color: white;
  cursor: pointer;
  position: absolute;
  bottom:0;
  right: 0;
}

.logoPuertaLogin{
  width: 100%;
}

.loginForm{
  width: 100%;
  outline: none;
}

.loginForm input{
  width: 100%;
  background-color: #926123;
  color: white;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top: 40px;
  border-color: white;
  margin-bottom: 10px;
}

.loginForm input::placeholder{
  color: white;
}

.loginForm button{
  width: 100%;
  background-color: #926123;
  border: 2px solid white;
  color:white;
  outline: none;
  cursor: pointer;
}

.loginForm button:hover{
  border: 2px solid #926123;
  background-color: white;
  color: #926123;
}



.directorioContainer{
  width: 100%;
  height: 100vh;
  padding: 5%;
  overflow-x: scroll;
  background-color: #333;
  background-image: url("http://puertadehierroac.mx/imagenes/iconos/fondoWeb.png");
  background-position:bottom;
  background-size:cover;
}

.directorioItemsContainer{
  background-color: red;
}

.header{
  color:#926123!important;
  font-family: "Heading-Regular"!important;
}


.description{
  color:white!important;

}

.enlaceTelefono{
  color:#ddbc91;
  text-decoration: none;

}

.enlaceTelefono:hover{
  color:#926123!important;
}



.padding-block {
  position: relative;
  font-size: 5vmax;

  background-color: #efefef;
  color: #333;
}

.scroll-carousel {
  background-color: #fff;
}

.sticky-box {
  position: sticky;
  top: 0;
  overflow: hidden;
  background-color: blue;
}

.transform-box {
  display: flex;
  will-change: transform;
}

.box {
  flex-shrink: 0;
  /* need to hardcode box sizes, else images loading throws off calculations */
  /* could be a job for imagesLoaded */
  width: 100%;
  height: 100vh;
  margin: 0 5rem;
  text-align: center;
  background-color: red;
}

.box--narrower {
  width: 100%;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.obras-container{
  width: 90%;
  height: 95vh;
  display:block;
  position: absolute;
  overflow-y:scroll;
}

.card-obras-container{
  width:60%;
  border: solid 2px #926123;
  border-radius: 10px;
  margin: 1%;
  padding: 1%;
  background-color: #adadad;
}

.card-obras-container h1{
  font-family:sans-serif;
}

.card-obras-container p{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.cardsRow-container{
  display: flex
}

.d-block.w-100{
  height: 280px!important;
}

.card-obras-images-container{

}

#cardDescription{
  height: 40px;
}

.card-obras-images-container img{
  width: 20%;
  margin:5px;
  max-height: 400px;
  cursor: pointer;
}


.reglamentosContainer{
  background: #373a47;
  height: 100vh;
  background-image: url("http://puertadehierroac.mx/imagenes/iconos/fondoWeb.png");
  background-position:bottom;
  background-size:cover;
}

.documentsContainer{
  display: flex;
}

.documentContainer{
  margin:4%;
  width: 50%;
  text-align: center;
}

.documentContainer img{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.documentContainer h1{
  color:black;
  font-size: 3.5vh;
  margin: 5%;
}

.documentContainer a{
  margin:5%;
  color:black;
}

.documentContainer a:hover{
  color:#926123
}

.consultaSaldoContainer{
  background-color: #373a47;
  height:100vh;
  background-image: url("http://puertadehierroac.mx/imagenes/iconos/fondoWeb.png");
  background-position:bottom;
  background-size:cover;
}

.datosCondominoContainer{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: white;
  font-size: 0.4px;
}

.datosCondomino{
  display: flex;
}

.datosCondomino h1{
  font-size: 1.3rem;
}

.datosCondomino h2{
  font-size: 1.3rem;
}

.datosCondomino h3{
  font-size: 1.3rem;
}
.noContratosContainer{
  width: 90%;
  margin:1%;
  margin-left: auto;
  margin-right: auto;
  color:white;
}

.noContratosContainer label{
  margin:1%;
}
.tablaDatosContainer{
  padding-left: 5%;
  padding-right: 5%;
}

.tablaDatos{
  width: 90%;
  height: 40vh;
  margin:1%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  overflow:auto;
}

.tablaDatos thead{
  background-color: #926123;
  color:white
}

.tablaDatos th{
  height: 10%;
  text-align: left;

}


.tablaDatos tr:nth-child(even) {
  background-color:#926223bd;
  color: white;
}

.datosTotalContainer{
  border:solid 2px #926123;
  border-radius: 5px;
  padding: 10px;
  color: white;
  width:max-content;
  margin-left: 79%;
}

.totalSaldosContainer{
  background-color: white;
  width: 20rem;
  margin-right: 5%;
  margin-top: 2%;
  padding:1%;
  border-radius: 10px;
  float: right;

}

.totalSaldosContainer h3{
  color: #926123;
  font-family: "Heading-Bold"!important;
  font-size: 1.3rem;
}

.totalSaldosContainer a{
  color:black!important;
  font-family: monospace;
}

.galeriaContainer{
  background-color: #373a47;
  height: auto;
  background-image: url("http://puertadehierroac.mx/imagenes/iconos/fondoWeb.png");
  background-position:bottom;
  background-size:cover;
}

.imagenesContainer{
  padding-top:1%;
  margin-left: 8%;
  width: 80%;
  height: auto;
}

.imagenesContainer h1{
  font-family:"Heading-Book"!important;
  color:white!important;
  font-size: 5vh;
}


@media(min-width:100px) and (max-width: 420px){

  .logoPuerta{
    margin-left: auto;
    margin-right: auto;
    padding-top: 15vh;
    display: block;
    width: 70%;
  }


  .img__wrap {
    position: relative;
    height: 150px;
    width: 200px;
  }

  .img__img{
    width: 100%;
  }


  .img__description_layer {
    position: absolute;
    height:70%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(36, 62, 206, 0.6);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
  }

  .img__wrap:hover .img__description_layer {
    visibility: visible;
    opacity: 1;
  }

  .img__description {
    transition: .2s;
    transform: translateY(1em);
  }

  .img__wrap:hover .img__description {
    transform: translateY(0);
  }



  .documentsContainer{
    display: block;
  }

  .documentContainer{
    margin:0%;
    margin-bottom: 30%;
    width: 100%;
    text-align: center;
  }

  .documentContainer h1{
    color:white;
    font-size: 2vh;
    margin: 0%;
  }

  .documentContainer a{
    margin:5%;
    color:white;

  }

.galeria {
  margin: auto;
}


}
